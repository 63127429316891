import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Form from '../common/forms/signup.js';
import Logo from '../../content/images/pinkspot_logo2.png';

const gradient = `url(${Logo})`;

export default () => (
  <StaticQuery
    query={graphql`
      query {
        heroJson {
          title
          body
        }
      }
    `}
    render={data => (
      <div
        className="min-vh-100 ph1 pv4 flex flex-column items-center justify-center"
        id="hero"
        style={{
          backgroundImage: gradient, 
          backgroundSize: 600, 
          backgroundPosition: "center", 
          backgroundRepeat: "no-repeat"
        }}>
        <Form  />
      </div>
    )}
  />
)
