import React from 'react';
import "./styles/contact.css"
import { MainCta } from '../buttons/cta.js';


const formClasses = "w-100 pv2 bg-transparent f4 bn contact__form"


class ContactForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {name: "", email: "", message: ""};
    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
  }

  handleName(event) {
    this.setState({name: event.target.value});
  }

  handleEmail(event) {
    this.setState({email: event.target.value});
  }

  handleMessage(event) {
    this.setState({message: event.target.value});
  }

  render() {
    return (
      <form action="https://cafe.us1.list-manage.com/subscribe/post?u=5e39107acb5c8dd674dfc4147&amp;id=29cfae7f47" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" novalidate>
        <div className="flex flex-wrap items-center justify-center">
          <div className="w-100 ph2 pv4">
            <input
              type="text"
              value={this.state.name}
              name="FNAME"
              className={formClasses}
              placeholder="Tu nombre"
              onChange={this.handleName}
              required />
          </div>
          <div className="w-100 ph2 pv4">
            <input
              type="email"
              value={this.state.email}
              name="EMAIL"
              className={formClasses}
              placeholder="Correo electrónico"
              onChange={this.handleEmail}
              required />
          </div>
          <div className="w-100 ph2 pv4">
            <textarea
              value={this.state.message}
              name="message"
              className={formClasses}
              placeholder="Preguntas, comentarios, inquietudes..."
              required
              rows="1"
              onChange={this.handleMessage} />
          </div>
          <input
            type="submit"
            value="Submit"
            className={MainCta + " w-100 mw5"} />
        </div>
      </form>
    )
  }
}

export default ContactForm;
